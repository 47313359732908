import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerService} from "../../services/customer.service";
import {CoolLocalStorage} from "@angular-cool/storage";
import {StateService} from "../../services/state.service";
import Swal from "sweetalert2";
import {environment} from "../../../environments/environment";

declare const intlTelInput: any;
declare const intlTelInputUtils: any;

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};
@Component({
  selector: 'app-passenger-signup',
  templateUrl: './passenger-signup.component.html',
  styleUrls: ['./passenger-signup.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    CustomerService
  ]
})
export class PassengerSignupComponent implements OnInit {
  loginError = false;
  language = 'en';
  loader = false;
  formGroup: FormGroup;
  formSubscription: Subscription;
  form: any = {config: {parts: [], widgetParts: []}};
  currentLanguage: any;

  input: HTMLElement;
  iti: any;
  isBlurred: boolean;
  phoneNumber: string;
  valid: boolean = true;
  hasErrors = false;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _vault: CoolLocalStorage,
    private router: Router,
    private state: StateService,
    private route: ActivatedRoute
  ) {

    let dataPointer = (this.activatedRoute.snapshot.params.formId ? this.activatedRoute.data : this.activatedRoute.parent.data);
    this.formSubscription = dataPointer.subscribe((data: { form: any }) => {
      this.form = data.form;
      this.language = this.activatedRoute.snapshot.paramMap.get('language') ?
        this.activatedRoute.snapshot.paramMap.get('language') :
        this.activatedRoute.parent.snapshot.paramMap.get('language');
    });
    this.phoneNumber = '';


    this.formGroup = new FormGroup({
      fname: new FormControl('',  []),
      lname: new FormControl('', []),
      email: new FormControl('', []),
      phoneNumber: new FormControl('', [])
    });
  }

  ngOnInit(): void {
  }

  tinyAlert(title, message, buttonText='ok', callback?) {
    Swal.fire({
      title: title,
      html: message,
      backdrop: true,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'confirm-button',
        title: 'confirm-container',
        htmlContainer: 'confirm-container-html',
      },
      confirmButtonColor: this.form.config.styles.colors['global-title-color'],
      confirmButtonText: this.translate.instant(buttonText),
      didClose: () => {
        if(callback) {
          callback();
        }
      }
    })
  }

  preFillForm() {
    this.formGroup.controls['fname'].setValue('Martin');
    this.formGroup.controls['lname'].setValue('Zwaneveld');
    this.formGroup.controls['email'].setValue('martin@tmplsolutions.nl');
    this.formGroup.controls['phoneNumber'].setValue('+31646096022');
  }

  addRequiredValidators() {
    this.formGroup.controls['fname'].setValidators([Validators.required]);
    this.formGroup.controls['lname'].setValidators([Validators.required]);
    this.formGroup.controls['email'].setValidators([Validators.required]);
    this.formGroup.controls['phoneNumber'].setValidators([Validators.required]);
    this.formGroup.controls['fname'].updateValueAndValidity();
    this.formGroup.controls['lname'].updateValueAndValidity();
    this.formGroup.controls['email'].updateValueAndValidity();
    this.formGroup.controls['phoneNumber'].updateValueAndValidity();
  }

  signupAccount(): void {
    this.addRequiredValidators();

    const self = this;

    if(this.formGroup.valid) {
      this.loader = true; // show loader
      this._customerService.createPassenger({
        "fname": this.formGroup.controls['fname'].value,
        "lname": this.formGroup.controls['lname'].value,
        "email": this.formGroup.controls['email'].value,
        "phoneNumber": this.formGroup.controls['phoneNumber'].value,
        "realm": this.state.realm,
        "companyId": this.state.realm,
        "branding": {
          "name": this.form.name,
          "url": `${environment.orderFormUrl}/dashboard/${this.language}/${this.form.id}`,
          "logo": `${this.form.config.logo}`,
          "sendAccountEmail": true
        }
      }).subscribe((data: any) => {
        self.tinyAlert(this.translate.instant('account_created_message_title'), this.translate.instant('account_created_message'), 'login', () =>{
          document.location = `dashboard/${this.language}/${this.form.id}/login`;
        });
        self.loader = false;
      }, (error) => {
        console.log(error.error.error.message);
        if(error.error.error && error.error.error.message.indexOf('User already exists') > -1) {
          self.tinyAlert(this.translate.instant('account_creation_failed_title'), this.translate.instant('account_failed_already_exists'));
        } else {
          self.tinyAlert(this.translate.instant('account_failed_message'), this.translate.instant('account_failed_message'));
        }
      });
    } else {
      let message = '';
      //Loop through the formGroup controls and get the validation errors
      Object.keys(this.formGroup.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            message += `* ${this.translate.instant(`account_creation_error_${keyError}_${key.toLowerCase()}`)}</br>`;
          });
        }
      })
      self.tinyAlert(this.translate.instant('account_failed_form_title'), message);
    }
  }

  validateAndPatchValue(event: any): void {
    if(this.iti.getNumber(intlTelInputUtils.numberFormat.E164)) {
      this.formGroup.controls['phoneNumber'].setValue(this.iti.getNumber(intlTelInputUtils.numberFormat.E164).replace(/ /g, ''));
      this.valid = this.iti.isValidNumber();
      if (!this.formGroup.controls['phoneNumber'].valid) {
        this.valid = false;
      }
    }
  }

  ngAfterViewInit(): void {
    const self = this;
    this.input = document.querySelector(`#phone-field input[type=tel]`);
    if (this.input) {
      this.input.addEventListener('countrychange', this.validateAndPatchValue.bind(this));

      let defaultCountry = this.state.language;
      if(!defaultCountry) {
        defaultCountry = 'nl';
      } else if(defaultCountry.toLowerCase() === 'en') {
        defaultCountry = 'gb';
      }

      this.iti = intlTelInput(this.input, {
        utilsScript: '//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.5.5/js/utils.js',
        preferredCountries: [],
        initialCountry: defaultCountry,
        separateDialCode: true,
        formatOnDisplay: false,
      });
    } else {
      setTimeout(function(){
        self.ngAfterViewInit();
      },100)
    }
  }
}
