<div class="wb-product-selection-wrapper {{(returnTrip ? 'wb-product-selection-active-return' : '')}} wb-{{layout}}">
  <div class="wb-product-selection-products {{(returnTrip ? 'wb-limitHeight' : '')}}">
    <div class="wb-product-selection-product" *ngFor="let product of products;">
      <div class="wb-product-selection-meta">
        <img src="{{product.imagePath}}" alt="{{product.name}}">
        <h3>{{product.descriptionTag | translate | ucFirst}}</h3>
        <p>{{'max' | translate | ucFirst}} {{product.maxPassengers}} {{'passengers_short' | translate | ucFirst}}</p>
        <p *ngIf="product.extraInfo">{{product.extraInfo}}</p>
        <p *ngIf="product.extraDescription">{{product.extraDescription}}</p>
      </div>
      <div class="wb-product-selection-prices">
        <div class="wb-product-selection-price">
          <span>
          <div>
            <span
              class="wb-product-selection-type">{{(this.form.config.orderType === 'hourly' ? 'total' :
              'product_button_one_way') | translate | ucFirst}}</span>
          </div>
          <div>
            <span class="wb-product-selection-amount" *ngIf="product.price.total>0">
              {{(product.currencySymbol ? product.currencySymbol :
              'currency_symbol_'+product.price.currency.toLowerCase() | translate | ucFirst)}}
              {{product.price.total / 100 | number:'1.2-2'}}
            </span>
            <span class="wb-product-selection-onmeter" *ngIf="!product.price || !product.price.total">{{'onmeter' | translate | ucFirst}}</span>
          </div>
          </span>
          <a href="javascript:void(0)" (click)="selectProduct(product)">{{'product_button_book' | translate | ucFirst}}</a>
        </div>
        <div class="wb-product-selection-price" *ngIf="formGroup.controls.destinationModel &&
        formGroup.controls.destinationModel.value && this.showReturn && this.form.config.orderType === 'route'">
          <span>
          <div>
              <span class="wb-product-selection-type">{{'product_button_return_trip' | translate | ucFirst}}</span>
          </div>
          <div>
            <span class="wb-product-selection-amount" *ngIf="product.totalPrice && product.totalPrice.total>0">
            {{(product.currencySymbol ? product.currencySymbol :
              'currency_symbol_'+product.totalPrice.currency.toLowerCase() | translate | ucFirst)}}
              {{product.totalPrice.total / 100 | number:'1.2-2'}}
          </span>
          <span class="wb-product-selection-onmeter" *ngIf="!product.totalPrice ||!product.totalPrice.total">{{'onmeter' | translate |
            ucFirst}} </span>
          </div>
          </span>
            <a href="javascript:void(0)" (click)="selectProduct(product, true)">{{'product_button_book' | translate | ucFirst}}</a>
        </div>

        <div class="wb-product-selection-extra-details-sub-section" *ngIf="this.productSummery && !this.forceSummaryTag && product.description"
             [innerHTML]="product.description | translate | html"></div>

        <div class="wb-product-selection-extra-details-sub-section" *ngIf="!this.forceSummaryTag && product.extraInfo && this.productExtraDescription && product.description"
             [innerHTML]="'<ul><li>'+product.description+'</li></ul>' | html"></div>

        <div class="wb-product-selection-extra-details-sub-section" *ngIf="this.forceSummaryTag && product.extraInfo"
             [innerHTML]="product.summaryTag | translate | html"></div>
      </div>

      <div class="wb-product-selection-break"></div>

      <div class="wb-product-selection-extra-details" *ngIf="this.forceSummaryTag && !product.extraInfo"
           [innerHTML]="product.summaryTag | translate | html"></div>

      <div class="wb-product-selection-extra-details" *ngIf="!this.forceSummaryTag && !product.extraInfo && !this.productSummery && this.productExtraDescription && product.description"
           [innerHTML]="'<ul><li>'+product.description+'</li></ul>' | html"></div>
    </div>
  </div>

  <div class="wb-product-selection-return {{(returnTrip ? 'product-selection-show' : '')}}">
    <div class="wb-product-selection-text">
      <h3>{{'return_trip_details_title' | translate | ucFirst}}</h3>
      <p>{{'return_trip_details_text' | translate | ucFirst}}</p>
    </div>
    <app-field-wrapper [className]="'field address'"
                       [summary]="true"
                       [index]="9999"
                       [field]="returnDepartureField"
                       [formGroup]="formGroup"
                       [mainConfig]="this.form.config"
                       [returnTrip]="true"
                       (openHelpModal)="openHelpModal.emit($event)"></app-field-wrapper>
    <app-field-wrapper *ngIf="returnDestinationField"
                       [className]="'field address'"
                       [summary]="true"
                       [index]="9999"
                       [field]="returnDestinationField"
                       [formGroup]="formGroup"
                       [returnTrip]="true"
                       [mainConfig]="this.form.config"
                       (openHelpModal)="openHelpModal.emit($event)"></app-field-wrapper>
    <ng-container *ngFor="let field of fields; index as fi;">
      <app-field-wrapper *ngIf="!field.hidden"
                         [className]="'field '+field.type"
                         [index]="fi"
                         [field]="field"
                         [minimumOrderTime]="minimumOrderTime"
                         [returnTrip]="true"
                         [formGroup]="formGroup"
                         [mainConfig]="this.form.config"
                         (openHelpModal)="openHelpModal.emit($event)"></app-field-wrapper>
    </ng-container>
  </div>
</div>
