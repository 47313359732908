export const AIRPORTS = {
  'nl': [
    {
      name: 'Schiphol Airport Amsterdam',
      code: 'AMS',
      gps: {
        lng: 4.759612,
        lat: 52.30943
      },
      streetName: 'Aankomstpassage',
      houseNumber: '1',
      postalCode: '1118AX',
      city: 'Schiphol',
      countryCode: 'NL'
    },
    {
      name: 'Eindhoven Airport',
      code: 'EIN',
      gps: {
        lng: 5.392199,
        lat: 51.457109
      },
      streetName: 'Luchthavenweg',
      houseNumber: '25',
      postalCode: '5657AA',
      city: 'Eindhoven',
      countryCode: 'NL'
    },
    {
      name: 'Rotterdam The Hague Airport',
      code: 'RTM',
      gps: {
        lng: 4.440794,
        lat: 51.959077
      },
      streetName: 'Rotterdam Airportplein',
      houseNumber: '60',
      postalCode: '3045AP',
      city: 'Rotterdam',
      countryCode: 'NL'
    },
    {
      name: 'Maastricht Aachen Airport',
      code: 'MST',
      gps: {
        lng: 5.769024,
        lat: 50.916157
      },
      streetName: 'Vliegveldweg ',
      houseNumber: '90',
      postalCode: '6199AD',
      city: 'Maastricht-Airport',
      countryCode: 'NL'
    },
    {
      name: 'Groningen Airport Eelde',
      code: 'GRQ',
      gps: {
        lng: 6.581025,
        lat: 53.124732
      },
      streetName: 'Machlaan',
      houseNumber: '14A',
      postalCode: '9761TK',
      city: 'Eelde',
      countryCode: 'NL'
    },
    {
      name: 'Brussel-Nationaal Zaventem',
      code: 'BRU',
      gps: {
        lng: 4.481545,
        lat: 50.898107
      },
      streetName: 'Leopoldlaan',
      houseNumber: '1',
      postalCode: '1934',
      city: 'Zaventem',
      land: 'België',
      countryCode: 'BE'
    },
    {
      name: 'Brussel Zuid - Charleroi',
      code: 'CRL',
      gps: {
        lng: 4.471318,
        lat: 50.471033
      },
      streetName: 'Rue des Frères Wright',
      houseNumber: '8',
      postalCode: '6041',
      city: 'Gosselies',
      countryCode: 'BE'
    },
    {
      name: 'Antwerpen Internationale Luchthaven',
      code: 'ANR',
      gps: {
        lng: 4.451147,
        lat: 51.188788
      },
      streetName: 'Luchthavenlei',
      houseNumber: '1',
      postalCode: '2100',
      city: 'Antwerpen',
      countryCode: 'BE'
    },
    {
      name: 'Ostende Airport',
      code: 'OST',
      gps: {
        lng: 2.869921,
        lat: 51.204679
      },
      streetName: 'Mariakerke + Raversijde',
      houseNumber: '1',
      postalCode: '8400',
      city: 'Oostende',
      countryCode: 'BE'
    },
    {
      name: 'Kortrijk Wevelgem Luchthaven',
      type: 'luchthaven',
      code: 'KJK',
      gps: {
        lng: 3.214059,
        lat: 50.819574
      },
      streetName: 'Luchthavenstraat',
      houseNumber: '1',
      postalCode: '8560',
      city: 'Wevelgem',
      countryCode: 'BE'
    },
    {
      name: 'Liège Airport',
      code: 'LGG',
      gps: {
        lng: 5.455463,
        lat: 50.638629
      },
      streetName: 'Rue de l\'Aéroport',
      houseNumber: '1',
      postalCode: '4460',
      city: 'Grâce-Hollogne',
      countryCode: 'BE'
    },
    {
      name: 'Düsseldorf International Airport',
      code: 'DUS',
      gps: {
        lng: 6.766167,
        lat: 51.279028
      },
      streetName: 'Flughafenstraße',
      houseNumber: '120',
      postalCode: '40474',
      city: 'Düsseldorf',
      countryCode: 'DE'
    },
    {
      name: 'Dortmund Airport ',
      code: 'DTM',
      gps: {
        lng: 7.618933,
        lat: 51.517075
      },
      streetName: 'Flughafenring',
      houseNumber: '2',
      postalCode: '44319',
      city: 'Dortmund',
      countryCode: 'DE'
    },
    {
      name: 'Frankfurt International Airport',
      code: 'FRA',
      gps: {
        lng: 8.576138,
        lat: 50.05222
      },
      streetName: 'Flughafen Regionalbahnhof',
      houseNumber: '1',
      postalCode: '60547',
      city: 'Frankfurt',
      countryCode: 'DE'
    },
    {
      name: 'Frankfurt-Hahn Flughafen',
      code: 'HHN',
      gps: {
        lng: 7.269688,
        lat: 49.939897
      },
      streetName: 'Gebäude',
      houseNumber: '667',
      postalCode: '55483',
      city: 'Hahn-Flughafen',
      countryCode: 'DE'
    },
    {
      name: 'Köln-Bonn Airport',
      code: 'CGN',
      gps: {
        lng: 7.120686,
        lat: 50.878669
      },
      streetName: 'Kennedystraße',
      houseNumber: '',
      postalCode: '51147',
      city: 'Köln',
      countryCode: 'DE'
    },
    {
      name: 'Münster-Osnabrück Flughafen',
      code: 'FMO',
      gps: {
        lng: 7.694324,
        lat: 52.130564
      },
      streetName: 'Hüttruper Heide',
      houseNumber: '71-81',
      postalCode: '48268',
      city: 'Greven',
      countryCode: 'DE'
    },
    {
      name: 'Weeze Airport',
      code: 'NRN',
      gps: {
        lng: 6.150262,
        lat: 51.599227
      },
      streetName: 'Flughafen-Ring',
      houseNumber: '60',
      postalCode: '47652',
      city: 'Weeze',
      countryCode: 'DE'
    },
    {
      name: 'Lelystad Airport',
      code: 'LEY',
      gps: {
        lng: 5.525302,
        lat: 52.455336
      },
      streetName: 'Pelikaanweg',
      houseNumber: '1',
      postalCode: '8218',
      city: 'Lelystad',
      countryCode: 'NL'
    },
    {
      name: 'City Airport Bremen',
      code: 'BRE',
      gps: {
        lng: 8.78351,
        lat: 53.054238
      },
      streetName: 'Flughafenallee',
      houseNumber: '20',
      postalCode: '28199',
      city: 'Bremen',
      countryCode: 'DE'
    },
    {
      name: 'Enschede Airport Twente',
      code: 'ENS',
      gps: {
        lng: 6.89785,
        lat: 52.263527
      },
      streetName: 'Oude Deventerweg',
      houseNumber: '1',
      postalCode: '7524PJ',
      city: 'Enschede',
      countryCode: 'NL'
    },
    {
      name: 'Hamburg Flughafen',
      code: 'HAM',
      gps: {
        lng: 10.006938,
        lat: 53.630359
      },
      streetName: 'Flughafenstraße',
      houseNumber: '1',
      postalCode: '22335',
      city: 'Hamburg',
      countryCode: 'DE'
    }
  ],
  'it': [
    {
      name: 'Ciampino Airport',
      code: 'CIA',
      gps: {
        lng: 12.5949001312,
        lat: 41.7994003296
      },
      streetName: 'Via Appia Nuova',
      houseNumber: '1651',
      postalCode: '00040',
      city: 'Roma',
      countryCode: 'NL'
    },
    {
      "name": "Leonardo Da Vinci (Fiumicino) International Airport",
      "code": "FCO",
      "city": "Fiumicino",
      "streetName": "Via Vincenzo Lunardi",
      "postalCode": "00054",
      "country": "IT",
      gps: {
        lng: 12.251508777702213,
        lat: 41.79455313889053
      },
    },
    {
      "name": "Milano Linate (LIN), Viale Forlanini, Segrate, Milaan, Italië",
      "code": "LIN",
      "city": "Milano",
      "streetName": "Viale Forlanini",
      "postalCode": "20090",
      "country": "IT",
      gps: {
        lng: 9.278907590270752,
        lat: 45.461216559067694
      },
    }
  ]
};
