<div class="loader-overlay" *ngIf="loader"></div>
<span id="loading" *ngIf="loader"></span>
<div #supportFrame class="support-frame" *ngIf="supportMode">
  <div class="support-title">
    <h2>{{'support_frame'|translate|ucFirst}}</h2>
    <button
      class="send-support-report {{(modalFormIndex !== 0 ? 'wb-button-small' : '')}} {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
      (click)="sendReport()">
      {{'send_report' | translate | ucFirst}}
    </button>
  </div>

  <div class="address-input">
    <h3>{{'order_details'|translate|ucFirst}}</h3>

    <table class="support-table">
      <tr *ngIf="formGroup.controls.departureModel.value">
        <td>{{'departure'|translate|ucFirst}}</td>
        <td>
          {{formGroup.controls.departureModel.value.internationalAlias}}
        </td>
      </tr>
      <tr *ngIf="formGroup.controls.destinationModel.value">
        <td>{{'destination'|translate|ucFirst}}</td>
        <td>
          {{formGroup.controls.destinationModel.value.internationalAlias}}
        </td>
      </tr>
      <tr *ngIf="formGroup.controls.requestedDate.value">
        <td>{{'requested_date'|translate|ucFirst}}</td>
        <td>
          {{formGroup.controls.requestedDate.value}}
        </td>
      </tr>
      <tr *ngIf="formGroup.controls.passengerCount.value">
        <td>{{'passengers'|translate|ucFirst}}</td>
        <td>
          {{formGroup.controls.passengerCount.value}}
        </td>
      </tr>
      <tr *ngIf="formGroup.controls.luggage && formGroup.controls.luggage.value">
        <td>{{'luggage'|translate|ucFirst}}</td>
        <td>
          {{formGroup.controls.luggage.value}}
        </td>
      </tr>
      <tr *ngIf="formGroup.controls.flightNumber && formGroup.controls.flightNumber.value">
        <td>{{'flightnumber'|translate|ucFirst}}</td>
        <td>
          {{formGroup.controls.flightNumber.value}}
        </td>
      </tr>
    </table>
  </div>

  <div #priceCalculation class="price-log" *ngIf="supportCalculationData.length > 0">
    <h3>{{'price_calculations'|translate|ucFirst}}</h3>

    <table class="support-table">
      <tr *ngIf="supportCalculationData && supportCalculationData[0]">
        <td>{{'metrics'|translate|ucFirst}}</td>
        <td colspan="3">
          {{(supportCalculationData[0].metrics.distance).toFixed(2)}}{{(state.companySettings.distanceFormat  === 'km'? 'km' : 'Mi')}} /
          {{Math.round(supportCalculationData[0].metrics.duration)}}min
        </td>
      </tr>
      <tr *ngIf="supportCalculationData && supportCalculationData[0].specialRates">
        <td>{{'special_rate'|translate|ucFirst}}</td>
        <td colspan="3">
          {{supportCalculationData[0].specialRates.ruleName}}
          - {{(supportCalculationData[0].specialRates.prices > 0 ?  'price_increase' : 'price_decrease') | translate | ucFirst}} {{(supportCalculationData[0].specialRates.type === 'percentage' ? supportCalculationData[0].specialRates.prices+'%'  : '')}}
          {{(supportCalculationData[0].specialRates.type === 'fixed' ? (supportCalculationData[0].specialRates.prices/100)  : '')  | currency:state.currency:'symbol':'1.2-2'}}
        </td>
      </tr>
      <tr>
        <th>{{'product'|translate|ucFirst}}</th>
        <th>{{'matched_rule'|translate|ucFirst}}</th>
        <th>{{'type'|translate|ucFirst}}</th>
        <th>{{'values'|translate|ucFirst}}</th>
      </tr>
      <tr *ngFor="let link of supportCalculationData; index as fi;" >
        <td>{{link.rule.productName}} / <strong>{{link.price.total / 100 | currency:state.currency:'symbol':'1.2-2'}}</strong></td>
        <td>{{link.rule.ruleName}}</td>
        <td>{{link.rule.type|translate|ucFirst}}</td>
        <td *ngIf="link.rule.type === 'fixed'">
          {{'fixed'|translate|ucFirst}} - {{link.rule.prices.fixedPrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
        </td>
        <td *ngIf="link.rule.type === 'dynamic'">
          {{'start'|translate|ucFirst}} - {{link.rule.prices.dynamicStartPrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
          {{'distance'|translate|ucFirst}} - {{link.rule.prices.dynamicDistancePrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
          {{'minimumprice'|translate|ucFirst}} - {{link.rule.prices.dynamicMinimumPrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
          {{'minute'|translate|ucFirst}} - {{link.rule.prices.dynamicMinutePrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
        </td>
      </tr>
    </table>
  </div>
  <div #priceCalculationReturn class="price-log" *ngIf="returnTripSupportData.length > 0">
    <h3>{{'price_calculations_returntrip'|translate|ucFirst}}</h3>
    <table class="support-table">
      <tr *ngIf="returnTripSupportData && returnTripSupportData[0]">
        <td>{{'metrics'|translate|ucFirst}}</td>
        <td colspan="3">
          {{(returnTripSupportData[0].metrics.distance).toFixed(2)}}{{(state.companySettings.distanceFormat  === 'km'? 'km' : 'Mi')}} /
          {{Math.round(returnTripSupportData[0].metrics.duration)}}min
        </td>
      </tr>
      <tr>
        <th>{{'product'|translate|ucFirst}}</th>
        <th>{{'matched_rule'|translate|ucFirst}}</th>
        <th>{{'type'|translate|ucFirst}}</th>
        <th>{{'values'|translate|ucFirst}}</th>
      </tr>
      <tr *ngFor="let link of returnTripSupportData; index as fi;" >
        <td>{{link.rule.productName}} / <strong>{{link.price.total / 100 | currency:state.currency:'symbol':'1.2-2'}}</strong></td>
        <td>{{link.rule.ruleName}}</td>
        <td>{{link.rule.type|translate|ucFirst}}</td>
        <td *ngIf="link.rule.type === 'fixed'">
          {{'fixed'|translate|ucFirst}} - {{link.rule.prices.fixedPrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
        </td>
        <td *ngIf="link.rule.type === 'dynamic'">
          {{'start'|translate|ucFirst}} - {{link.rule.prices.dynamicStartPrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
          {{'distance'|translate|ucFirst}} - {{link.rule.prices.dynamicDistancePrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
          {{'minimumprice'|translate|ucFirst}} - {{link.rule.prices.dynamicMinimumPrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
          {{'minute'|translate|ucFirst}} - {{link.rule.prices.dynamicMinutePrice / 100 | currency:state.currency:'symbol':'1.2-2'}}<br>
        </td>
      </tr>
    </table>
  </div>
  <div #tagManager class="tagmanager-log">
    <h3>{{'tag_manager'|translate|ucFirst}}</h3>

    <ul>
      <li *ngFor="let link of analytics.localLayer; index as fi;" >
        {{link.event}} {{(link.virtualPageURL ? ' - ' : '')}} {{link.virtualPageURL}}
      </li>
    </ul>
  </div>
</div>

<div class="wb-processing-payment" *ngIf="processingPayment">
  <div class="wb-order-text">
    <div class="wb-lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h3>{{'processing_payment' | translate | ucFirst}}</h3>
    <p>{{'processing_payment_text' | translate | ucFirst}}</p>
  </div>
</div>
<div *ngIf="(!onlyShowLoader)" [class]="(supportMode ? 'support-mode' : '')">
  <nav class=" wb-top wb-formstyle-{{formStyle}}" *ngIf="(showlogo || showbreadcrumbs) && formStyle !== 'widget' &&
form.config.parts[selectedFormIndex] && !['completion','failed'].includes(form.config.parts[selectedFormIndex].type)">
    <div class="wb-contain wb-page-{{selectedFormIndex}}">
      <div class="wb-logo wb-page-{{selectedFormIndex}}">
        <span class="helper" *ngIf="showlogo && form.config.logo"></span>
        <img src="{{form.config.logo}}" alt="logo" class="wb-logo" *ngIf="showlogo && form.config.logo">
        <h1 class="wb-logo wb-logotext wb-page-{{selectedFormIndex}}"
            *ngIf="showlogo && !form.config.logo">{{form.name}}</h1>
      </div>
      <div class="wb-main" *ngIf="showbreadcrumbs">
        <h1 class="wb-formtitle">{{'form_title' | translate | ucFirst}}</h1>
        <div class="wb-steps">
          <a *ngFor="let part of steps; index as i;"
             (click)="(i < selectedFormIndex ? navigate('previous', i) : '')"
             href="javascript:void(0)"
             class="wb-{{(i === selectedFormIndex ? 'active' : '')}}">
            <span>{{i + 1}}</span>
            {{part.title  | translate | ucFirst}}
          </a>
        </div>
      </div>
    </div>
  </nav>
  <form [formGroup]="formGroup"
        class="wb-form-container wb-page-{{selectedFormIndex}} wb-formstyle-{{formStyle}} wb-navigation-style-{{(formConfig === 'widget'? formConfig : navigation)}}"
        autocomplete="off"
        [style.padding-bottom.px]="(formStyle === 'webbooker' && formNavigation ? (formNavigation.offsetHeight-25) : 0)"
        *ngIf="formGroup && formStyle !== 'widget'">
    <!-- First field is to throw off browsers from using autocomplete -->
    <input autocomplete="off" name="hidden" type="text" style="display:none;">

    <div class="wb-form-slider"
         [ngStyle]="{width: (form.config.parts.length) * 100 + '%', margin: '0 0 0 -'+selectedFormIndex * 100+'%'}">

      <div class="wb-form-part {{part.type}}"
           id="page-{{i}}"
           *ngFor="let part of formParts; index as i;">

        <div class="{{(part.type === 'summary' ? '' : 'booking-order-pane')}}">
          <div class="wb-fields wb-tab-pane" *ngIf="selectedFormIndex === 0 && form.config.hourlyBookings &&
          form.config.routeBookings">
            <div class='wb-form_tab {{(activeTab === "route" ? "active" : "")}}' (click)="activateTab('route')">
              {{'route_bookings' | translate | ucFirst}}
            </div>
            <div class='wb-form_tab {{(activeTab === "hourly" ? "active" : "")}}' (click)="activateTab('hourly')">
              {{'hourly_bookings' |  translate | ucFirst}}
            </div>
          </div>

          <div class="wb-fields wb-order-form-fields">
            <app-product-selection *ngIf="part.type === 'products' && i === selectedFormIndex"
                                   [index]="i"
                                   [form]="form"
                                   [minimumOrderTime]="this.form.config.minimumOrderTime"
                                   [products]="products"
                                   (selectionFinished)="navigate($event)"
                                   (scrollUp)="scrollUp()"
                                   class="wb-formstyle-{{formStyle}}"
                                   (openHelpModal)="toggleHelpModal($event)"></app-product-selection>

            <app-summary *ngIf="part.type === 'summary' && i === selectedFormIndex"
                         [index]="i"
                         class="wb-formstyle-{{formStyle}}"
                         [form]="form"
                         [formGroup]="formGroup"
                         [selectedIndex]="selectedFormIndex"
                         [totalPrice]="totalPrice"
                         [singlePrice]="singlePrice"
                         [transactionCosts]="transactionCosts"
                         [formNavigation]="productSelectionCode"
                         (bookOrder)="bookOrder()"
                         (navigate)="navigate($event)"
                         [returnPrice]="returnPrice"></app-summary>

            <div *ngIf="i === selectedFormIndex && !['completed','failed'].includes(part.type)" class="wb-field-container">
              <ng-container *ngFor="let field of part.fields; index as fi;">
                <app-field-wrapper
                  *ngIf="formGroup && part.type !== 'products' && part.type !== 'summary' && !field.hidden"
                  [minimumOrderTime]="this.form.config.minimumOrderTime"
                  [className]="'wb-field wb-'+field.type+' '+field.cssclass+''"
                  [index]="fi" [field]="field"
                  [formGroup]="formGroup"
                  [part]="part"
                  [mainConfig]="this.form.config"
                  (checkWorkArea)="checkWorkAreas()"
                  (openHelpModal)="toggleHelpModal($event)"></app-field-wrapper>
              </ng-container>
            </div>
          </div>
        </div>

        <app-confirmation [status]="orderStatus" [navigation]="navigation" [parent]="this"
                          *ngIf="i === selectedFormIndex && ['completion'].includes(part.type)"></app-confirmation>

        <div class="wb-sidebar" *ngIf="!['completed','failed'].includes(part.type)">
          <h3 *ngIf="part.sidebar && part.sidebar.title">{{part.sidebar.title | translate | ucFirst}}</h3>
          <span *ngIf="part.sidebar && part.sidebar.text" [innerHTML]="part.sidebar.text | translate | html"></span>
          <ng-container *ngIf="part.sidebar && part.sidebar.module && part.sidebar.module === 'summary'">
            <app-sidebar-summary *ngIf="formGroup && formParts && form"
                                 #sidebarSummary
                                 [parts]="formParts"
                                 [formGroup]="formGroup"
                                 [form]="form"
                                 [selectedFormIndex]="selectedFormIndex"></app-sidebar-summary>

            <app-preview-route-map #routeMap
                                   [form]="form" [formGroup]="formGroup" [metrics]="metrics"
                                   [buttonstyle]="buttonstyle"
                                   *ngIf="this.form.config.showRouteMap &&
                                   this.formGroup.controls.departureModel.value && i === selectedFormIndex"></app-preview-route-map>
          </ng-container>
        </div>
      </div>
    </div>
  </form>

  <ngx-smart-modal #helpModal identifier="helpModal" customClass="nsm-dialog-animation-ttb help-modal-{{helpData.type}}">
    <h3>
      <fa-icon [icon]="faInfoCircle"></fa-icon>
      {{helpData.helpTitleTag | translate | ucFirst}}
    </h3>
    <p [innerHTML]="helpData.helpTextTag | translate | ucFirst"></p>
    <span (click)="helpModal.close()" class="wb-close">{{'close' | translate | ucFirst}}</span>
  </ngx-smart-modal>

  <ngx-smart-modal #supportReportModal identifier="supportReportModal" class="support-report-modal">
    <h3 class="support-report-modal-title">
      <fa-icon [icon]="faInfoCircle"></fa-icon>
      {{helpData.helpTitleTag | translate | ucFirst}}
    </h3>
    <p [innerHTML]="helpData.helpTextTag | translate | ucFirst" id="supportMail"></p>
    <button
      class="send-support-report wb-button-small {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
      (click)="copyAndClose('supportMail')">
      {{'copy_and_close' | translate | ucFirst}}
    </button>
  </ngx-smart-modal>

  <ngx-smart-modal #serviceAreaModal identifier="serviceAreaModal" customClass="nsm-dialog-animation-ttb" class="service-area-modal">
    <h3>
      <fa-icon [icon]="faInfoCircle"></fa-icon>
      {{helpData.helpTitleTag | translate | ucFirst}}
    </h3>
    <p *ngIf="helpData && this.myPolygon">
      <google-map #serviceAreaMap height="480px" width="480px" *ngIf="googleMapsLoaded" [options]="{disableDefaultUI:true}">
        <map-polygon [paths]="this.serviceArea" [options]="this.myPolygon"></map-polygon>
        <map-marker [options]="departureMarker" [position]="formGroup.controls.departureModel.value.gps"></map-marker>
        <map-marker [options]="destinationMarker" [position]="formGroup.controls.destinationModel && this.formGroup.controls.destinationModel.value && this.formGroup.controls.destinationModel.value.gps"></map-marker>
      </google-map>
    </p>
    <span (click)="serviceAreaModal.close()" class="wb-close">{{'close' | translate | ucFirst}}</span>
  </ngx-smart-modal>

  <ngx-smart-modal #navigationErrorModal identifier="navigationErrorModal" customClass="navigation-error nsm-dialog-animation-ttb">
    <h3 class="navigate-error-title">
      <fa-icon [icon]="faExclamationTriangle" class="navigate-error-icon"></fa-icon>
      <div [innerHTML]="navigationError.title | translate | ucFirst"></div>
      <span (click)="navigationErrorModal.close()" class="wb-close">{{'close' | translate | ucFirst}}</span>
    </h3>
    <p class="navigate-error-text" [innerHTML]="navigationError.text | translate | ucFirst"></p>
  </ngx-smart-modal>

  <ngx-smart-modal #paymentModal_{{productSelectionCode}} identifier="paymentModal_{{productSelectionCode}}"
                   customClass="nsm-dialog-animation-ttb"
                   (onClose)="closeModal()" (onDismiss)="closeModal()">
    <iframe #paymentModalIframe class="stripe-iframe"></iframe>
    <h3 class="navigate-error-title">
      <span (click)="navigationErrorModal.close()" class="wb-close">{{'close' | translate | ucFirst}}</span>
    </h3>
  </ngx-smart-modal>

  <ngx-smart-modal #paymentPreauthModal identifier="paymentPreauthModal"
                   customClass="payment-preauth-model nsm-dialog-animation-ttb"
                   (onDismiss)="paymentPreauthModal.close()">
    <h3 [innerHTML]="'preauth_in_taxi_payment_title' | translate | ucFirst"></h3>
    <div [innerHTML]="'preauth_in_taxi_payment_message' | translate | ucFirst"></div>
    <div class="wb-container" style="padding-top:10px;">
      <button
        class="wb-button-small {{(buttonstyle==='squared'? 'wb-squared' : '')}}" (click)="paymentPreauthModal.close()">
        {{'close' | translate | ucFirst}}
      </button>
      <button
        class="wb-button-small {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
        (click)="createPayment(true);paymentPreauthModal.close();">
        {{'preauth_amount_button' | translate | ucFirst}}
      </button>
    </div>
  </ngx-smart-modal>

  <ngx-smart-modal #priceUpdate identifier="priceUpdate"
                   customClass="payment-preauth-model nsm-dialog-animation-ttb"
                   (onDismiss)="paymentPreauthModal.close()">
    <h3 [innerHTML]="'price_update_title' | translate | ucFirst"></h3>
    <span [innerHTML]="'price_update_message' | translate | ucFirst"></span>
    <span *ngIf="priceUpdateButton">
    {{(state.currencySymbol ? state.currencySymbol :
    'currency_symbol_'+priceUpdateButton.totalPrice.currency.toLowerCase() | translate | ucFirst)}}{{priceUpdateButton.totalPrice.total / 100 | number:'1.2-2'}}
      </span>
    <div class="wb-container" style="padding-top:10px;">
      <button
        class="wb-button-small {{(buttonstyle==='squared'? 'wb-squared' : '')}}" (click)="priceUpdate.close()">
        {{'close' | translate | ucFirst}}
      </button>
      <button
        class="wb-button-small {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
        (click)="selectProduct();priceUpdate.close();">
        {{'accept_price' | translate | ucFirst}}
      </button>
    </div>
  </ngx-smart-modal>
  <ngx-smart-modal #termsAndConditionsModal identifier="termsAndConditionsModal"
                   customClass="payment-preauth-model nsm-dialog-animation-ttb"
                   (onDismiss)="termsAndConditionsModal.close()">
    <div [innerHTML]="'accept_terms_message' | translate | ucFirst" class="text-container"></div>
    <div class="wb-container" style="padding-top:10px;">
      <button
        class="wb-button-small {{(buttonstyle==='squared'? 'wb-squared' : '')}}" (click)="termsAndConditionsModal.close()">
        {{'close' | translate | ucFirst}}
      </button>
      <button
        class="wb-button-small {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
        (click)="acceptTermsAndBook();priceUpdate.close();">
        {{'accept_terms_and_book' | translate | ucFirst}}
      </button>
    </div>
  </ngx-smart-modal>
  <nav #formNavigation id="{{productSelectionCode}}_formNavigation"
       class="wb-form wb-page-{{selectedFormIndex}} wb-formstyle-{{formStyle}}"
       *ngIf="formStyle !== 'widget' &&
     form.config.parts[selectedFormIndex]
     && !['completion','failed'].includes(form.config.parts[selectedFormIndex].type)">
    <div class="wb-desktop wb-error-list {{isErroneous ? 'active' : ''}}">
      <span *ngFor="let error of globalErrors">{{error.text | translate | ucFirst}}</span>
    </div>
    <div class="wb-container{{(formGroup.controls.returnTrip.value ? ' retour-nav' : '')}}">
      <button
        class="wb-previous {{(selectedFormIndex !== 0 ? 'wb-button-small' : '')}} {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
        *ngIf="selectedFormIndex !== 0"
        (click)="navigate('back')">
        {{'text_back_button' | translate | ucFirst}}
      </button>
      <button *ngIf="canShowNextButton()"
              class="wb-next {{(selectedFormIndex !== 0 ? 'wb-button-small' : '')}} {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
              (click)="navigate('next')">
        {{(selectedFormIndex === 0 ? 'text_next_page_1_button' : 'text_next_button' ) | translate | ucFirst}}
      </button>
      <button
        class="wb-next {{(selectedFormIndex !== 0 ? 'wb-button-small' : '')}} {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
        *ngIf="selectedFormIndex === (form.config.parts.length - 2)"
        (click)="bookOrder()">
        {{'process_order_button' | translate | ucFirst}}
      </button>
    </div>
    <span class="wb-poweredBy" *ngIf="form.config.poweredBy" href="https://www.yourdriverapp.com"
          target="_new">Powered by YourDriverApp.com</span>
  </nav>

  <ngx-smart-modal #productSelection_{{productSelectionCode}} identifier="productSelection_{{productSelectionCode}}"
                   customClass="nsm-dialog-animation-ttb"
                   (onClose)="closeModal()" (onDismiss)="closeModal()" *ngIf="navigation === 'modal'">
    <nav class="wb-top wb-modal-top" *ngIf="showlogo || showbreadcrumbs">
      <div class="wb-contain wb-page-{{modalFormIndex}}">
        <div class="wb-logo wb-page-{{selectedFormIndex}}"></div>
        <div class="wb-main" *ngIf="showbreadcrumbs">
          <h1 class="wb-formtitle">{{'form_title' | translate | ucFirst}}</h1>
          <div class="wb-steps">
            <a *ngFor="let part of steps; index as i;"
               (click)="(i < modalFormIndex ? navigate('previous', i) : '')"
               href="javascript:void(0)"
               class="wb-{{(i === modalFormIndex ? 'active' : '')}}">
              <span>{{i + 1}}</span>
              {{part.title  | translate | ucFirst}}
            </a>
          </div>
        </div>
      </div>
    </nav>

    <form [formGroup]="formGroup" class="wb-form-container wb-page-{{modalFormIndex}} wb-formstyle-{{formStyle}}"
          autocomplete="off">
      <!-- First field is to throw off browsers from using autocomplete -->
      <input autocomplete="off" name="hidden" type="text" style="display:none;">

      <div class="wb-form-slider"
           [ngStyle]="{width: (form.config.parts.length) * 100 + '%', margin: '0 0 0 -'+modalFormIndex * 100+'%'}">
        <div class="wb-form-part {{part.type}}"
             id="page-{{i}}"
             *ngFor="let part of form.config.parts; index as i;"
             [ngStyle]="{width: 100 / form.config.parts.length + '%'}">
          <div class="{{(part.type === 'summary' ? '' : 'booking-order-pane')}}">
            <div class="wb-fields" *ngIf="!['completion'].includes(part.type)">
              <app-product-selection *ngIf="part.type === 'products' && i === modalFormIndex"
                                     [index]="i"
                                     [form]="form"
                                     [minimumOrderTime]="this.form.config.minimumOrderTime"
                                     [products]="products"
                                     (selectionFinished)="navigate($event)"
                                     (scrollUp)="scrollUp()"
                                     (openHelpModal)="toggleHelpModal($event)"></app-product-selection>

              <app-summary *ngIf="part.type === 'summary' && i === modalFormIndex"
                           [index]="i"
                           [form]="form"
                           [formGroup]="formGroup"
                           [selectedIndex]="modalFormIndex"
                           [totalPrice]="totalPrice"
                           [singlePrice]="singlePrice"
                           [transactionCosts]="transactionCosts"
                           [formNavigation]="productSelectionCode"
                           (bookOrder)="bookOrder()"
                           (navigate)="navigate($event)"
                           [returnPrice]="returnPrice"></app-summary>


              <div *ngIf="i === modalFormIndex">
                <ng-container *ngFor="let field of part.fields; index as fi;">
                  <app-field-wrapper
                    *ngIf="formGroup && part.type !== 'products' && part.type !== 'summary' && !field.hidden"
                    [minimumOrderTime]="this.form.config.minimumOrderTime"
                    [className]="'wb-field wb-'+field.type+' '+field.cssclass+''"
                    [index]="fi" [field]="field"
                    [formGroup]="formGroup"
                    [part]="part"
                    [mainConfig]="this.form.config"
                    (openHelpModal)="toggleHelpModal($event)"
                    (checkWorkArea)="checkWorkAreas()"
                  ></app-field-wrapper>
                </ng-container>
              </div>
            </div>
          </div>

          <app-confirmation [status]="orderStatus" [navigation]="navigation" [parent]="this"
                            *ngIf="i === selectedFormIndex && ['completion'].includes(part.type)"></app-confirmation>

          <div class="wb-sidebar" *ngIf="!['completion'].includes(part.type)">
            <h3 *ngIf="part.sidebar && part.sidebar.title">{{part.sidebar.title | translate | ucFirst}}</h3>
            <span *ngIf="part.sidebar && part.sidebar.text" [innerHTML]="part.sidebar.text | translate | html"></span>
            <ng-container *ngIf="part.sidebar && part.sidebar.module && part.sidebar.module === 'summary'">
              <app-sidebar-summary *ngIf="formGroup && formParts && form"
                                   [parts]="formParts"
                                   [formGroup]="formGroup"
                                   [form]="form"
                                   [selectedFormIndex]="modalFormIndex"></app-sidebar-summary>

            </ng-container>
          </div>

          <app-confirmation [status]="orderStatus" [navigation]="navigation" [parent]="this"
                            *ngIf="i === modalFormIndex && ['completion'].includes(part.type)"></app-confirmation>
        </div>
      </div>
    </form>
    <nav class="wb-widget-form wb-page-{{modalFormIndex}}" id="{{productSelectionCode}}_formNavigationModal"
         *ngIf="form.config.parts[modalFormIndex]
       && !['completion','failed'].includes(form.config.parts[modalFormIndex].type)">
      <div class="wb-desktop wb-error-list {{isErroneous ? 'active' : ''}}">
        <span *ngFor="let error of globalErrors">{{error.text | translate | ucFirst}}</span>
      </div>
      <div class="wb-container{{(this.formGroup.controls.returnTrip.value ? ' retour-nav' : '')}}">
        <button
          class="wb-previous {{(modalFormIndex !== 0 ? 'wb-button-small' : '')}} {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
          *ngIf="modalFormIndex !== 0"
          (click)="navigate('back')">
          {{'text_back_button' | translate | ucFirst}}
        </button>
        <button *ngIf="canShowNextButton('modal')"
                class="wb-next {{(modalFormIndex !== 0 ? 'wb-button-small' : '')}} {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
                (click)="navigate('next')">
          {{'text_next_button' | translate | ucFirst}}
        </button>
        <button
          class="wb-next {{(modalFormIndex !== 0 ? 'wb-button-small' : '')}} {{(buttonstyle==='squared'? 'wb-squared' : '')}}"
          *ngIf="modalFormIndex === (form.config.parts.length - 2)"
          (click)="bookOrder()">
          {{'process_order_button' | translate | ucFirst}}
        </button>

        <a class="wb-poweredBy" *ngIf="form.config.poweredBy" href="https://www.yourdriverapp.com" target="_new">Powered
          by
          YourDriverApp.com</a>

        <a class="wb-close" (click)="this.closeMyModal()" *ngIf="showclose"
           target="_new">{{'close'|translate|ucFirst}}</a>

      </div>
    </nav>
  </ngx-smart-modal>

  <div *ngIf="formStyle === 'widget' && !modalOpen && widgettype === 'popup-widget'"
       class="wb-popup-widget wb-{{this.buttonposition}}"
       style="background-color:{{this.buttonbgcolor}};color:{{this.buttontextcolor}}"
       (click)="navigate('next', 0)"
  >{{this.buttontext|translate|ucFirst}}</div>
</div>
