import {
  ChangeDetectorRef,
  Component,
  EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output, ViewChild, ViewEncapsulation
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Field} from '../../interfaces/field';
import {Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {StateService} from '../../services/state.service';
import {FieldErrorService} from '../../services/field-error.service';
import {faChevronDown, faChevronUp, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {AddressFieldComponent} from "../address-field/address-field.component";

declare const window: any;

@Component({
  selector: 'app-field-wrapper',
  templateUrl: './field-wrapper.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./field-wrapper.component.scss']
})
export class FieldWrapperComponent implements OnInit, OnDestroy {
  @Input() field: Field | any;
  @Input() index: number;
  @Input() summary = false;
  @Input() returnTrip = false;
  @Input() layout = 'page-layout';
  @Input() form: any = {config: {parts: []}};
  @Input() minimumOrderTime = 60;
  @Input() mainConfig;
  @Input() part:any;
  @Output() openHelpModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkWorkArea: EventEmitter<any> = new EventEmitter<any>();
  @Input() formGroup: UntypedFormGroup;
  @ViewChild(AddressFieldComponent) addressField: AddressFieldComponent;
  orderMode: string;
  orderModeSub: Subscription;
  airportDeparture: boolean;
  airportDepartureSub: Subscription;
  airportDestination: boolean;
  airportDestinationSub: Subscription;

  opened: boolean;
  hasErrors = false;
  formChanges: Subscription;
  faInfoCircle = faInfoCircle;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  constructor(@Inject(DOCUMENT) private document: Document,
              private state: StateService,
              private errorService: FieldErrorService,
              private changeDetection: ChangeDetectorRef) {
    const _self = this;
    this.opened = false;
    this.orderModeSub = this.state.orderMode$.subscribe(
      orderMode => this.orderMode = orderMode
    );

    if(typeof this.state.airportDeparture === 'undefined') {
      this.airportDepartureSub = this.state.airportDeparture$.subscribe(
        (airportDeparture) => {
          if (_self.field && _self.field.property) {
            if (_self.returnTrip) {
              _self.airportDestination = airportDeparture;
            } else {
              _self.airportDeparture = airportDeparture;
            }
          }
        });
      this.airportDestinationSub = this.state.airportDestination$.subscribe((airportDestination) => {
        if (_self.field && _self.field.property) {
          if (_self.returnTrip) {
            _self.airportDeparture = airportDestination;
          } else {
            _self.airportDestination = airportDestination;
          }
        }
      });
    }
  }

  ngOnInit() {
    const _self = this;
    //this.formGroup = this.state.formGroup;
    if (this.field.type !== 'collapse') {
      this.formChanges = this.formGroup.valueChanges.subscribe(this.setFieldState.bind(this));
    } else {
      this.opened = this.field.opened;
    }

    if(typeof this.state.airportDeparture !== 'undefined') {
      if (_self.returnTrip) {
        _self.airportDestination = this.state.airportDeparture;
      } else {
        _self.airportDeparture = this.state.airportDeparture;
      }

      if (_self.returnTrip) {
        _self.airportDeparture = this.state.airportDestination;
      } else {
        _self.airportDestination = this.state.airportDestination;
      }
    }
    this.setFieldState(this.formGroup.getRawValue());
  }

  setFieldState(values) {
    const self = this;
    if (!self.returnTrip) {
      self.airportDeparture = this.state.airportDeparture;
      self.airportDestination = this.state.airportDestination;
    } else {
      self.airportDeparture = this.state.airportDestination;
      self.airportDestination = this.state.airportDeparture;
    }

    if (self.field.type === 'collapse' || self.field.type === 'sortable-address') {
      self.opened = !self.field.dynamic;
    } else if (self.formGroup.controls[self.field.property]) {
      if (self.field.showIf) {
        // console.log(`----------------------------------------`);
        let shouldOpen = true;
        /**
         * We need to loop through the showIf object to see if we should open the field
         * Each of the options should be true
         */

        Object.keys(self.field.showIf).forEach((key) => {
          if(!shouldOpen) return;
          // console.log(`----------------------------------------`);
          // console.log(`self.field.showIf`, self.field.showIf);
          // console.log(`key`, key);
          if(key === 'orderMode' && !self.orderMode) return;
          if (key === 'addressAirport' && self.field.showIf[key]) {
            if (self.field.showIf[key] === 'any' &&
              (self.airportDeparture || self.airportDestination)) {
              shouldOpen = true;
            } else if (self.field.showIf[key] === 'departure' && self.airportDeparture) {
              shouldOpen = true;
            } else if (self.field.showIf[key] === 'destination' && self.airportDestination) {
              shouldOpen = true;
            } else {
              shouldOpen = false;
            }
          } else if (this[key] && this[key] === self.field.showIf[key]) {
            shouldOpen = true;
            // console.log(`Opening field '${self.field.property}' cause it was found in 'this'`);
          } else if (values[key] && values[key] === self.field.showIf[key]) {
            shouldOpen = true;
            // console.log(`Opening field '${self.field.property}' cause it was found in field values`);
          } else {
            shouldOpen = false;
            // console.log(`shouldOpen:`, shouldOpen);
          }
        });
        self.opened = shouldOpen;

        // console.log(`Field '${self.field.property}' should open -> ${shouldOpen}`);
        if (!shouldOpen && self.formGroup.controls[self.field.property].value !== self.field.default) {
          // console.log(`Restoring default for field '${self.field.property}'`);
          // self.formGroup.controls[self.field.property].setValue(self.field.default);
        }
        // console.log(`field ${self.field.property} is ` + (self.opened ? 'opened' : 'closed'));
        // console.log('------');
      } else {
        self.opened = true;
      }
    }
    if (self.changeDetection) {
      self.changeDetection.detectChanges();
    }
    if ((self.field.type === 'text' || self.field.type === 'email') && self.field.required) {
      if (self.formGroup.controls[self.field.property].value !== '' && self.errorService.fieldHasErrors(self.field.property)) {
        self.errorService.removeError(`${self.field.property}Required`);
      }
    }
  }

  toggleSection(id: string) {
    // console.log(`toggleSection(${id})`);
    this.opened = !this.opened;
    const section = this.document.getElementById(id);
    setTimeout(() => {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: section.offsetTop
      });
    }, 300);
  }

  toggleHelpModal(helpTitleTag: string, helpTextTag: string, type?: string, meta?: any) {
    this.openHelpModal.emit({
      helpTitleTag,
      helpTextTag,
      type,
      meta,
    });
  }

  checkWorkAreaEmit() {
    this.checkWorkArea.emit();
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

  ngOnDestroy(): void {
    if (this.formChanges) {
      this.formChanges.unsubscribe();
    }
    if (this.orderModeSub) {
      this.orderModeSub.unsubscribe();
    }
    if (this.airportDepartureSub) {
      this.airportDepartureSub.unsubscribe();
    }
    if (this.airportDestinationSub) {
      this.airportDestinationSub.unsubscribe();
    }
  }

  addError(error: any) {
    this.addressField.errors.addError(error);

  }

  removeError(error: any) {
    this.addressField.errors.removeError(error.id);
  }
}
