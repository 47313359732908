import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FormModule } from './form/form.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import {CoolLocalStorage, CoolStorageModule} from '@angular-cool/storage';
import { FieldErrorService } from './services/field-error.service';
import { AnalyticsService } from './services/analytics.service';
import { AsyncTranslationLoader } from './async-translation-loader';
import { StateService } from './services/state.service';
import { VisitorService } from './services/visitor.service';
import { NoContractComponent } from './no-contract/no-contract.component';
import {UcFirstPipeModule} from './pipes/uc-first.pipe';
import {SharedModule} from './shared/shared.module';
import {InlineWebbookerModule} from '../integrations/inline-webbooker/inline-webbooker.module';
import {PopupWidgetModule} from '../integrations/popup-widget/popup-widget.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {RouterModule} from '@angular/router';
import {TranslationService} from "./services/translation-service.service";
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { GlobalTableComponent } from './global/global-table/global-table.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new AsyncTranslationLoader(http, '', '');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    NoContractComponent,
    GlobalTableComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient,CoolLocalStorage,StateService]
      }
    }),
    FormsModule,
    FormModule,
    SharedModule,
    PopupWidgetModule,
    InlineWebbookerModule,
    DashboardModule,
    CoolStorageModule.forRoot(),
    RouterModule.forRoot([]),
    AppRoutingModule,
    UcFirstPipeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  exports: [
    TranslateModule,
    UcFirstPipeModule
  ],
  providers: [
    { provide: 'Window',  useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CoolStorageModule,
    StateService,
    VisitorService,
    AnalyticsService,
    FieldErrorService,
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
  private _translate: TranslateService;

  constructor(
    _translate: TranslateService,
  ) {
    this._translate = _translate;
    /**
     * The lang to use, if the lang isn't available, it will use the current loader to get them
     */
    _translate.use('en');
  }
}
