import {Component, Inject, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../services/state.service';
import { Subscription } from 'rxjs';
import { CoolLocalStorage } from '@angular-cool/storage';
import { AnalyticsService } from '../../services/analytics.service';
import {DOCUMENT} from '@angular/common';
import {environment} from "../../../environments/environment";
import {InlineWebbookerComponent} from "../../../integrations/inline-webbooker/inline-webbooker.component";
import {TranslationService} from "../../services/translation-service.service";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @Input() status: string;
  @Input() navigation: string;
  @Input() parent: InlineWebbookerComponent;

  loadedScripts: any = {};
  form: any = {config: {parts: []}};
  logo: string;
  statuses = {
    COMPLETED: 'completed',
    FAILED: 'failed',
    BOOKED: 'booked',
  };
  dataSubscription: Subscription;
  retryUrl = '';
  formId = '';

  constructor(private activatedRoute: ActivatedRoute,
              private state: StateService,
              private vault: CoolLocalStorage,
              private analytics: AnalyticsService,
              private route: ActivatedRoute,
              private router: Router,
              private _renderer2: Renderer2,
              private _translateService: TranslateService,
              private domSanitizer:DomSanitizer,
              @Inject('Window') private window: Window,
              @Inject(DOCUMENT) private document: Document) {
    this.status = activatedRoute.snapshot.params.status;
    this.dataSubscription = (this.activatedRoute.parent ? this.activatedRoute.parent : this.activatedRoute).data.subscribe((data: {form: any}) => {
      if(data && data.form) {
        this.logo = data.form.config.logo;
        this.formId = data.form.id;
        this.form = data.form;
      }
    });
    this.loadScript(`https://js.stripe.com/v3/`);
  }

  loadScript(url, callback=null) {
    if(this.loadedScripts[url]) {
      return;
    }
    const self = this;
    let script = this._renderer2.createElement('script');
    script.src = url;
    let done = false;
    script.onload = script.onreadystatechange = function() {
      if ( !done && (!this.readyState ||
        this.readyState === "loaded" || this.readyState === "complete") ) {
        done = true;
        if (callback) {
          self[callback]();
        }
        // Handle memory leak in IE
        script.onload = script.onreadystatechange = null;
      }
    };
    this._renderer2.appendChild(this.document.body, script);
    this.loadedScripts[url] = true;
  }

  retryPayment(): void {
    const paymentMeta = this.vault.getObject(this.state.storageKeys.paymentMeta);
    if (paymentMeta && paymentMeta[`gateway`] === 'stripe') {
      const options = { };

      if (this.form.config.payment.stripeAccountId) {
        // @ts-ignore
        options.stripeAccount = this.form.config.payment.stripeAccountId;
      }
      // @ts-ignore
      const stripe = Stripe((this.form.config.payment.stripeAccountId ? environment.ydaStripePublicKey : this.form.config.payment.publicKey), options);
      stripe.redirectToCheckout({sessionId: paymentMeta[`sessionId`]});
    } else if(paymentMeta) {
      this.document.location.href = paymentMeta[`paymentUrl`];
    } else {
      this.bookNewRide();
    }
  }

  ngOnInit(): void {
    this.analytics.pageView(`/${this.formId}/${(this.status === this.statuses.BOOKED ? 'booked' : `payment-${this.status}`)}`);
  }

  getInnerHTMLValue(){
    return this.domSanitizer.bypassSecurityTrustHtml(this._translateService.instant(`order_confirmation_text_${this.status}`));
  }


  bookNewRide() {
    const language = (this.state.language ? this.state.language : this.route.snapshot.paramMap.get('language') ? this.route.snapshot.paramMap.get('language') : this.route.parent.snapshot.paramMap.get('language'));
    this.state.reset();

    if(this.navigation === 'inline') {
      /**
       * reload page
       */
      this.window.location.reload();
    } else
      if (this.form.config.returnUrl) {
      document.location = this.form.config.returnUrl;
    } else {
      document.location = `/${language}/${(this.form.config.customUrl ? this.form.config.customUrl : this.state.formId)}`;
    }
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

}
