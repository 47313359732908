import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { Field } from '../../interfaces/field';
import {UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, startWith, takeUntil} from 'rxjs/operators';
import moment from 'moment-timezone';
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-field-value',
  templateUrl: './field-value.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./field-value.component.scss']
})
export class FieldValueComponent implements OnInit, OnDestroy {

  @Input() field: Field;
  @Input() formGroup: UntypedFormGroup;
  @Input() form: any = {config: {parts: []}};
  @Input() orderMode: string;
  @Input() airportDeparture: boolean;
  @Input() airportDestination: boolean;
  @Input() summary = false;
  timeZoneOffset: string;
  displayTime: string;
  private subscr:Subscription;
  changesSubscription: Subscription;
  private destroy$ = new Subject();

  constructor(
    private changeDetection: ChangeDetectorRef,
    private _state: StateService,
  ) {
  }

  ngOnInit() {
    if (this.field.type === 'dateTime') {
      this.processDate();
    }
  }

  processDate(): void {
    if (moment.tz.zone(this.form.config.timeZone)) {
      this.subscr = this.formGroup.controls[this.field.property].valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(this.changeDate.bind(this));
      this.timeZoneOffset = moment.tz.zone(this.form.config.timeZone).abbr(new Date(this.formGroup.controls[this.field.property].value).getTime());

      const dateFormat   = (this._state.companySettings.dateFormat !== 'MMM Do YYYY' ? 'D-M-YYYY' : this._state.companySettings.dateFormat);

      console.log(this._state.companySettings.dateFormat);

      this.displayTime = moment(this.formGroup.controls[this.field.property].value).tz(this.form.config.timeZone).format(dateFormat+ ' ' + (this._state.companySettings.clock === '12' ? 'hh' : 'HH') + ':mm' + (this._state.companySettings.clock === '12' ? ' a' : ''));
    }
    this.changesSubscription = this.formGroup.get(this.field.property).valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(changes => {
      this.changeDetection.detectChanges();
    })
  }

  changeDate(value: any): void {
    this.processDate();
  }

  isSelectValue(option: any, selected: any) {
    return (
      (option === selected)
      ||
      (option !== '' && Number(option) === Number(selected))
    );
  }

  ngOnDestroy(): void {
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }
    if (this.subscr) {
      this.subscr.unsubscribe();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getArray() {
    if(!this.field.property) {
      this.field.property = 'stopOvers';
    }
    if(this.field && this.field.property) {
      // @ts-ignore
      return this.formGroup.controls[this.field.property]?.controls;
    } else {
      return [];
    }
  }
}
