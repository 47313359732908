// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  translationId: '5d88988ecf78f843c7a711b4',
  translationUrl: 'https://localization-api.dispatchapi.io/api/Projects',
  libraryUrl: 'https://library.dispatchapi.io',
  apiUrl: 'https://s-order.dispatchapi.io',
  orderFormUrl: 'https://s-order.yourdriverapp.com',
  pasUrl: 'https://s-pas.dispatchapi.io',
  ydaStripePublicKey: 'pk_test_51Hk3p7AAAqH6BJkpNpMpwWtSOW5HnpzPXNYImfzbuZilQtW7PAZ0bZcadDSPyul7CT9gEDrrEtt70JcMXcdPq9h3004NacQkCX',
  customDomains: {
    'www.gatwickcars.com': '5dc038453325ca757f678630',
    'order.gatwickcars.com': '5dc038453325ca757f678630',
    'www.heathrowcars.com': '5dc038453325ca757f678630',
    'order.roadrunnersgroup.com': '5dc038453325ca757f678630',
    'booking.schipholtraveltaxi.nl': '5dc038453325ca757f678630',
    'booking.schipholbusinesstaxi.nl': '5dc038453325ca757f678630',
    'www.vfashuttle.com': '62f67d523019db31b30f1738',
    'www.shuttles.co.zw': '62f6c6a2c326cc0ec2bfd83c',
    'booking.taxiserviceairportrome.com': '645d0cc10c2023e5ffd26264',
    'booking.tsairportransfers.com': '63a491cd88b78c65535a565f',
    'partnerbooking.taxiserviceairportrome.com': '64d15bd4c20eea1e8f3b9ef3',
  }
  // apiUrl: 'http://[::1]:3000/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
